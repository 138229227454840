/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~swiper/swiper-bundle.min.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css'; 
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/rtl";
@import "app/theme/styles/custom";
@import "app/theme/styles/heromap";

.green-snackbar div {    
    border-radius: 5px;
    background: rgb(33, 100, 58) !important;
    color: white;
}
.green-snackbar button {
    background-color: rgb(33, 100, 58);
    color: white;
    border: none;
}
.red-snackbar div {
    border-radius: 5px;
    background: #c22a1f !important;
    color: white;
}
.red-snackbar button {
    background-color: #c22a1f;
    color: white !important;
    border: none;
}

.black-snackbar div {
    border-radius: 5px;
    color: white;
}
.black-snackbar button {
    color: white !important;
    border: none;
}